/*make sure to import all the other css files here for them to work in the project*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@layer base {
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
  }
  @font-face {
    font-family: "DMSans";
    src: url("../fonts/DMSans/DMSans.ttf");
  }
}

.react-datepicker-wrapper {
  display: flex !important;
}
.popup-content {
  background-color: white !important;
  width: 666px !important;
  min-height: 743px !important;
  box-shadow: 0px 4px 10px 0px #00000026 !important;
  border-radius: 18px !important;
  padding: 24px !important;
}
.scroll-min-height {
  overflow-y: auto !important;
  height: 542px !important;
}
/* WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.Toastify__toast {
  height: auto !important;
  min-height: 38px !important;
}
/* toastStyles.css */
.custom-toast {
  font: Inter !important;
  font-weight: normal !important;
  font-family: Inter;
  font-size: 15px !important;
  line-height: 18.15px !important;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000 !important;
  border-radius: 8px !important;
}
.custom-toast-success {
  background-color: #e9ffe5 !important;
}
.custom-toast-error {
  background-color: rgb(243, 217, 217);
}
.react-datepicker__year-dropdown {
  height: 200px !important;
  overflow: scroll !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: Just in case there's a background color in the container */
}

.custom-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.MuiButton-text {
  padding: 0px 0px !important;
  width: 15px !important;
  height: 15px !important;
  min-width: unset !important;
  margin-left: 2px !important;
}
.MuiButton-label {
  padding-top: 4px !important;
}
.MuiTooltip-tooltip {
  max-width: unset !important;
}
.MuiTooltip-popper {
  top: -11px !important;
  left: -9px !important;
}
.MuiTooltip-arrow {
  top: 12px !important;
}
.submit-detail-tooltip .MuiButton-label {
  padding-top: 0px !important;
}

